.account {
  &__content {
    background: #fff;
    padding: 0 20px 50px;
    margin: 0 -20px;
  }
  &__header {
    overflow: hidden;
    width: 100%;
    padding: 40px 0 20px;
  }
  &__section-header {
    clear: both;
    font-size: 12px;
    border-bottom: 1px dashed #ccc;
    padding: 30px 0 10px 180px;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #777;
  }
  &__title {
    float: left;
    margin: 0;
    padding-top: 5px;
    font-size: 24px;
    font-weight: 500;
    color: #382244;
    text-shadow: -1px -1px 0 #fff
  }
  &__item {
    width: 100%;
    overflow: auto;
    padding: 0 0 10px;
  }
  &__label {
    float: left;
    text-align: right;
    width: 180px;
    padding: 10px 20px 0 0;
    color: #a6a7c1;
    font-weight: bold;
    font-size: 14px;
  }
  &__input,
  &__input-file {
    float: left;
    width: 500px;
    font-size: 14px;
  }
  &__legal-name,
  &__mobile-number,
  &__phone-number,
  &__email,
  &__tax-id {
    height: 40px;
    width: 100%;
    font-size: 14px;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  &__action {
    clear: both;
    border-top: 1px dashed #ccc;
    margin-top: 10px;
    padding: 20px 0 0 180px;
  }
  &__submit {
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 3px;
    border: 0;
    color: #fff;
    background: #00C5DD;
  }
  &__cancel {
    font-size: 16px;
    margin-left: 20px;
    text-decoration: none;
    color: #a6a7c1;
    &:hover {
      text-decoration: underline;
    }
  }
  &__file-preview {
    float: left;
    margin-right: 10px;
    width: 200px;
    height: 200px;
    line-height: 200px;
    border: 1px solid #ccc;
    background: #fafafa;
    text-align: center;
    white-space: nowrap;
    * {
      vertical-align: middle;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      white-space: nowrap;
    }
  }
  &__file-label {
    margin: 0;
    font-size: 14px;
    padding: 10px 0;
  }
  &__report {
    padding: 10px 10px 10px 180px;
    font-size: 14px;
    margin: 10px 0 -10px;
    &_error {
      color: #e73d4a;
      background: #fbe1e3;
    }
    &_success {
      color: #27a4b0;
      background: #e9f9fb;
    }
  }
}
