.register {
  &__container {
    width: 410px;
    margin: 0 auto;
    background-color: #29A3BB;
  }
  &__header {
    padding: 15px 20px 7px;
    border-bottom: 1px solid #fff;
    overflow: hidden;
  }
  &__logo {
    float: left;
  }
  &__title {
    float: right;
    margin: 0;
    padding: 4px 0 0;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }
  &__content {
    padding: 20px;
  }
  &__error-message {
    padding: 11px;
    margin-bottom: 20px;
    color: #fff;
    background: #d84d4d;
  }
  &__success-message {
    display: none;
    padding: 11px;
    background: #cae431;
  }
  &__form-wrapper {
    padding-bottom: 15px;
  }
  &__text {
    border: 0;
    margin: 0;
    padding: 15px;
    width: 100%;
    font-size: 16px;
    &_error {
      border: 1px solid red;
      background-color: #fee;
    }
  }
  &__select {
    width: 100%;
    font-size: 16px;
    height: 50px;
    border-radius: 0;
  }
  &__entity-info {
    font-size: 14px;
    color: #fff;
    padding: 5px 11px 15px;
    margin: 0;
  }
  &__action {
    overflow: hidden;
    padding-top: 20px;
  }
  &__button {
    float: left;
    padding: 15px 60px;
    text-decoration: none;
    color: #005567;
    font-size: 14px;
    font-weight: bold;
    background: #CAE431;
    text-shadow: 1px 1px 1px rgba(255,255,255,0.5);
    &:hover {
      background: darken(#CAE431, 5);
    }
  }
  &__forgot {
    float: right;
    margin-top: 13px;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
    }
  }

  &_success &__form-wrapper,
  &_success &__button {
    display: none;
  }
  &_success &__success-message {
    display: block;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .login {
    background-color: #29A3BB;
    &__container {
      width: 100%;
      bottom: 0;
      position: absolute;
    }
    &__header {
      padding: 0 0 50px;
      border: 0;
      text-align: center;
    }
    &__logo {
      height: 45px;
      width: 165px;
    }
    &__action {
      text-align: center;
      padding-bottom: 20px;
    }
    &__button {
      float: none;
      display: block;
      width: 100%;
      margin-bottom: 20px;
    }
    &__forgot {
      float: none;
    }
  }
}
