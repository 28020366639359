.campaign {
  &__content {
    background: #fff;
    padding: 0 20px 50px;
    margin: 0 -20px;
  }
  &__header {
    overflow: hidden;
    width: 100%;
    padding: 40px 0 20px;
  }
  &__title {
    float: left;
    margin: 0;
    padding-top: 5px;
    font-size: 24px;
    font-weight: 500;
    color: #382244;
    text-shadow: -1px -1px 0 #fff
  }
  &__new {
    float: right;
    font-size: 14px;
    padding: 8px 10px;
    border-radius: 3px;
    color: #fff;
    text-decoration: none;
    background: #00C5DD;
    &:hover {
      background: #29A3BB;
    }
  }
  table {
    margin-top: 30px;
  }
  th {
    color: #a6a7c1;
    border-bottom: 1px solid #f4f5f8;
    padding: 10px 0;
  }
  td {
    color: #575958;
    border-bottom: 1px solid #f4f5f8;
    padding: 10px 0;
  }
  & &__nodata {
    padding: 20px 0;
    text-align: center;
    color: #a6a7c1;
    font-size: 14px;
  }
  & &__loading {
    padding: 20px 0;
    text-align: center;
  }
  &__col-title {
    text-align: left;
  }
  &__col-type {
    text-align: left;
    width: 90px;
  }
  &__col-date {
    text-align: left;
    width: 160px;
  }
  &__col-actions {
    text-align: right;
    width: 80px;
  }
  &__tdTitle {
    font-weight: bold;
  }
  &__tdDesc {
    padding-top: 5px;
    font-size: 12px;
  }
  &__type {
    font-size: 12px;
    padding: 5px 8px;
    border-radius: 4px;
    background: #ddd;
    &_1 {
      background-color: rgba(152,22,244,0.1);
    }
    &_2 {
      background-color: rgba(0,197,220,0.1);
    }
    &_3 {
      background-color: rgba(244,81,108,0.1);
    }
  }
  &__action {
    display: inline-block;
    width: 34px;
    height: 34px;
    padding-top: 8px;
    text-align: center;
    color: #c1bfd0;
    border-radius: 20px;
    &:hover {
      color: #fff;
      background-color: #00C5DD;
    }
    &_delete:hover {
      background-color: #ff8282;
    }
  }
}
