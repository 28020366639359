.campaign-form {
  padding: 0 0 20px;

  &__item {
    float: left;
    width: 100%;
    padding: 0 0 10px;
  }
  &__section-header {
    clear: both;
    font-size: 12px;
    border-bottom: 1px dashed #ccc;
    padding: 30px 0 10px 180px;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #777;
  }
  &__hosts {
    border-bottom: 1px dashed #ccc;
    padding: 0 0 20px 0;
    float: left;
    width: 100%;
  }
  &__hosts-info {
    padding-left: 180px;
    font-size: 14px;
    color: #a6a7c1;
  }
  &__add-host {
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 3px;
    border: 0;
    color: #fff;
    background: #00C5DD;
  }
  &__action {
    clear: both;
    padding: 20px 0 0 180px;
  }
  &__submit {
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 3px;
    border: 0;
    color: #fff;
    background: #00C5DD;
  }
  &__cancel {
    font-size: 16px;
    margin-left: 20px;
    text-decoration: none;
    color: #a6a7c1;
    &:hover {
      text-decoration: underline;
    }
  }
  &__label {
    float: left;
    text-align: right;
    width: 180px;
    padding: 10px 20px 0 0;
    color: #a6a7c1;
    font-weight: bold;
    font-size: 14px;
  }
  &__input {
    float: left;
    width: 500px;
    font-size: 14px;
  }
  &__date-start,
  &__date-end {
    height: 40px;
    width: 200px;
    font-size: 14px;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  &__description {
    width: 100%;
    height: 60px;
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  &__title {
    height: 40px;
    width: 100%;
    font-size: 14px;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  &__date-separator {
    padding: 0 10px;
    color: #a6a7c1;
    font-weight: bold;
    font-size: 14px;
  }
  &__motorbike,
  &__car {
    height: 40px;
    width: 80px;
    font-size: 14px;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  &__info {
    padding: 0 10px;
    color: #a6a7c1;
    font-size: 14px;
  }

  &__report {
    padding: 10px 10px 10px 180px;
    font-size: 14px;
    margin: 10px 0 -10px;
    &_error {
      color: #e73d4a;
      background: #fbe1e3
    }
  }
}
