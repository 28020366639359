.campaign-new {
  &__content {
    background: #fff;
    padding: 0 20px 50px;
    margin: 0 -20px;
  }
  &__header {
    overflow: hidden;
    width: 100%;
    padding: 40px 0 20px;
  }
  &__title {
    float: left;
    margin: 0;
    padding-top: 5px;
    font-size: 24px;
    font-weight: 500;
    color: #382244;
    text-shadow: -1px -1px 0 #fff
  }
}
