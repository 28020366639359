body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

.container {
  width: 1024px;
  margin: 0 auto;
}

.header {
  height: 60px;
  background: #29A3BB; }
  .header__logo-wrapper {
    float: left;
    height: 60px;
    padding: 15px 30px 0 0; }
  .header__logo {
    float: left; }
  .header__nav {
    float: left;
    height: 60px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    padding: 20px 15px 0; }
    .header__nav:hover, .header__nav_selected {
      background: #168BA2; }
  .header__right {
    float: right; }

.footer {
  padding: 30px 0;
  text-align: center;
  font-size: 14px;
  color: #c1bfd0; }

.campaign__content {
  background: #fff;
  padding: 0 20px 50px;
  margin: 0 -20px; }

.campaign__header {
  overflow: hidden;
  width: 100%;
  padding: 40px 0 20px; }

.campaign__title {
  float: left;
  margin: 0;
  padding-top: 5px;
  font-size: 24px;
  font-weight: 500;
  color: #382244;
  text-shadow: -1px -1px 0 #fff; }

.campaign__new {
  float: right;
  font-size: 14px;
  padding: 8px 10px;
  border-radius: 3px;
  color: #fff;
  text-decoration: none;
  background: #00C5DD; }
  .campaign__new:hover {
    background: #29A3BB; }

.campaign table {
  margin-top: 30px; }

.campaign th {
  color: #a6a7c1;
  border-bottom: 1px solid #f4f5f8;
  padding: 10px 0; }

.campaign td {
  color: #575958;
  border-bottom: 1px solid #f4f5f8;
  padding: 10px 0; }

.campaign .campaign__nodata {
  padding: 20px 0;
  text-align: center;
  color: #a6a7c1;
  font-size: 14px; }

.campaign .campaign__loading {
  padding: 20px 0;
  text-align: center; }

.campaign__col-title {
  text-align: left; }

.campaign__col-type {
  text-align: left;
  width: 90px; }

.campaign__col-date {
  text-align: left;
  width: 160px; }

.campaign__col-actions {
  text-align: right;
  width: 80px; }

.campaign__tdTitle {
  font-weight: bold; }

.campaign__tdDesc {
  padding-top: 5px;
  font-size: 12px; }

.campaign__type {
  font-size: 12px;
  padding: 5px 8px;
  border-radius: 4px;
  background: #ddd; }
  .campaign__type_1 {
    background-color: rgba(152, 22, 244, 0.1); }
  .campaign__type_2 {
    background-color: rgba(0, 197, 220, 0.1); }
  .campaign__type_3 {
    background-color: rgba(244, 81, 108, 0.1); }

.campaign__action {
  display: inline-block;
  width: 34px;
  height: 34px;
  padding-top: 8px;
  text-align: center;
  color: #c1bfd0;
  border-radius: 20px; }
  .campaign__action:hover {
    color: #fff;
    background-color: #00C5DD; }
  .campaign__action_delete:hover {
    background-color: #ff8282; }

.campaign-form {
  padding: 0 0 20px; }
  .campaign-form__item {
    float: left;
    width: 100%;
    padding: 0 0 10px; }
  .campaign-form__section-header {
    clear: both;
    font-size: 12px;
    border-bottom: 1px dashed #ccc;
    padding: 30px 0 10px 180px;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #777; }
  .campaign-form__hosts {
    border-bottom: 1px dashed #ccc;
    padding: 0 0 20px 0;
    float: left;
    width: 100%; }
  .campaign-form__hosts-info {
    padding-left: 180px;
    font-size: 14px;
    color: #a6a7c1; }
  .campaign-form__add-host {
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 3px;
    border: 0;
    color: #fff;
    background: #00C5DD; }
  .campaign-form__action {
    clear: both;
    padding: 20px 0 0 180px; }
  .campaign-form__submit {
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 3px;
    border: 0;
    color: #fff;
    background: #00C5DD; }
  .campaign-form__cancel {
    font-size: 16px;
    margin-left: 20px;
    text-decoration: none;
    color: #a6a7c1; }
    .campaign-form__cancel:hover {
      text-decoration: underline; }
  .campaign-form__label {
    float: left;
    text-align: right;
    width: 180px;
    padding: 10px 20px 0 0;
    color: #a6a7c1;
    font-weight: bold;
    font-size: 14px; }
  .campaign-form__input {
    float: left;
    width: 500px;
    font-size: 14px; }
  .campaign-form__date-start, .campaign-form__date-end {
    height: 40px;
    width: 200px;
    font-size: 14px;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid #ccc; }
  .campaign-form__description {
    width: 100%;
    height: 60px;
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc; }
  .campaign-form__title {
    height: 40px;
    width: 100%;
    font-size: 14px;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid #ccc; }
  .campaign-form__date-separator {
    padding: 0 10px;
    color: #a6a7c1;
    font-weight: bold;
    font-size: 14px; }
  .campaign-form__motorbike, .campaign-form__car {
    height: 40px;
    width: 80px;
    font-size: 14px;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid #ccc; }
  .campaign-form__info {
    padding: 0 10px;
    color: #a6a7c1;
    font-size: 14px; }
  .campaign-form__report {
    padding: 10px 10px 10px 180px;
    font-size: 14px;
    margin: 10px 0 -10px; }
    .campaign-form__report_error {
      color: #e73d4a;
      background: #fbe1e3; }

.campaign-new__content {
  background: #fff;
  padding: 0 20px 50px;
  margin: 0 -20px; }

.campaign-new__header {
  overflow: hidden;
  width: 100%;
  padding: 40px 0 20px; }

.campaign-new__title {
  float: left;
  margin: 0;
  padding-top: 5px;
  font-size: 24px;
  font-weight: 500;
  color: #382244;
  text-shadow: -1px -1px 0 #fff; }

.campaign-edit__content {
  background: #fff;
  padding: 0 20px 50px;
  margin: 0 -20px; }

.campaign-edit__header {
  overflow: hidden;
  width: 100%;
  padding: 40px 0 20px; }

.campaign-edit__title {
  float: left;
  margin: 0;
  padding-top: 5px;
  font-size: 24px;
  font-weight: 500;
  color: #382244;
  text-shadow: -1px -1px 0 #fff; }

.account__content {
  background: #fff;
  padding: 0 20px 50px;
  margin: 0 -20px; }

.account__header {
  overflow: hidden;
  width: 100%;
  padding: 40px 0 20px; }

.account__section-header {
  clear: both;
  font-size: 12px;
  border-bottom: 1px dashed #ccc;
  padding: 30px 0 10px 180px;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #777; }

.account__title {
  float: left;
  margin: 0;
  padding-top: 5px;
  font-size: 24px;
  font-weight: 500;
  color: #382244;
  text-shadow: -1px -1px 0 #fff; }

.account__item {
  width: 100%;
  overflow: auto;
  padding: 0 0 10px; }

.account__label {
  float: left;
  text-align: right;
  width: 180px;
  padding: 10px 20px 0 0;
  color: #a6a7c1;
  font-weight: bold;
  font-size: 14px; }

.account__input, .account__input-file {
  float: left;
  width: 500px;
  font-size: 14px; }

.account__legal-name, .account__mobile-number, .account__phone-number, .account__email, .account__tax-id {
  height: 40px;
  width: 100%;
  font-size: 14px;
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid #ccc; }

.account__action {
  clear: both;
  border-top: 1px dashed #ccc;
  margin-top: 10px;
  padding: 20px 0 0 180px; }

.account__submit {
  font-size: 16px;
  padding: 10px 15px;
  border-radius: 3px;
  border: 0;
  color: #fff;
  background: #00C5DD; }

.account__cancel {
  font-size: 16px;
  margin-left: 20px;
  text-decoration: none;
  color: #a6a7c1; }
  .account__cancel:hover {
    text-decoration: underline; }

.account__file-preview {
  float: left;
  margin-right: 10px;
  width: 200px;
  height: 200px;
  line-height: 200px;
  border: 1px solid #ccc;
  background: #fafafa;
  text-align: center;
  white-space: nowrap; }
  .account__file-preview * {
    vertical-align: middle; }
  .account__file-preview img {
    max-width: 100%;
    max-height: 100%;
    white-space: nowrap; }

.account__file-label {
  margin: 0;
  font-size: 14px;
  padding: 10px 0; }

.account__report {
  padding: 10px 10px 10px 180px;
  font-size: 14px;
  margin: 10px 0 -10px; }
  .account__report_error {
    color: #e73d4a;
    background: #fbe1e3; }
  .account__report_success {
    color: #27a4b0;
    background: #e9f9fb; }

.login {
  text-align: center; }
  .login__container {
    width: 410px;
    margin: 0 auto 40px;
    text-align: left;
    background-color: #29A3BB; }
  .login__header {
    padding: 15px 20px 7px;
    border-bottom: 1px solid #fff;
    overflow: hidden; }
  .login__logo {
    float: left; }
  .login__title {
    float: right;
    margin: 0;
    padding: 4px 0 0;
    color: #fff;
    font-size: 16px;
    font-weight: bold; }
  .login__content {
    padding: 20px; }
  .login__error-message {
    padding: 11px;
    margin-bottom: 20px;
    color: #fff;
    background: #d84d4d; }
  .login__register {
    font-weight: bold;
    color: #29A3BB;
    text-decoration: none; }
  .login__form-wrapper {
    padding-bottom: 15px; }
  .login__text {
    border: 0;
    margin: 0;
    padding: 15px;
    width: 100%;
    font-size: 16px; }
    .login__text_error {
      border: 1px solid red;
      background-color: #fee; }
  .login__action {
    overflow: hidden;
    padding-top: 20px; }
  .login__button {
    float: left;
    padding: 15px 60px;
    text-decoration: none;
    color: #005567;
    font-size: 14px;
    font-weight: bold;
    background: #CAE431;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5); }
    .login__button:hover {
      background: #c2de1d; }
  .login__forgot {
    float: right;
    margin-top: 13px;
    text-decoration: none;
    color: #fff;
    font-size: 14px; }
    .login__forgot:hover {
      text-decoration: underline; }

@media (min-width: 320px) and (max-width: 480px) {
  .login {
    background-color: #29A3BB; }
    .login__container {
      width: 100%;
      bottom: 0;
      position: absolute; }
    .login__header {
      padding: 0 0 50px;
      border: 0;
      text-align: center; }
    .login__logo {
      height: 45px;
      width: 165px; }
    .login__action {
      text-align: center;
      padding-bottom: 20px; }
    .login__button {
      float: none;
      display: block;
      width: 100%;
      margin-bottom: 20px; }
    .login__forgot {
      float: none; } }

.register__container {
  width: 410px;
  margin: 0 auto;
  background-color: #29A3BB; }

.register__header {
  padding: 15px 20px 7px;
  border-bottom: 1px solid #fff;
  overflow: hidden; }

.register__logo {
  float: left; }

.register__title {
  float: right;
  margin: 0;
  padding: 4px 0 0;
  color: #fff;
  font-size: 16px;
  font-weight: bold; }

.register__content {
  padding: 20px; }

.register__error-message {
  padding: 11px;
  margin-bottom: 20px;
  color: #fff;
  background: #d84d4d; }

.register__success-message {
  display: none;
  padding: 11px;
  background: #cae431; }

.register__form-wrapper {
  padding-bottom: 15px; }

.register__text {
  border: 0;
  margin: 0;
  padding: 15px;
  width: 100%;
  font-size: 16px; }
  .register__text_error {
    border: 1px solid red;
    background-color: #fee; }

.register__select {
  width: 100%;
  font-size: 16px;
  height: 50px;
  border-radius: 0; }

.register__entity-info {
  font-size: 14px;
  color: #fff;
  padding: 5px 11px 15px;
  margin: 0; }

.register__action {
  overflow: hidden;
  padding-top: 20px; }

.register__button {
  float: left;
  padding: 15px 60px;
  text-decoration: none;
  color: #005567;
  font-size: 14px;
  font-weight: bold;
  background: #CAE431;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5); }
  .register__button:hover {
    background: #c2de1d; }

.register__forgot {
  float: right;
  margin-top: 13px;
  text-decoration: none;
  color: #fff;
  font-size: 14px; }
  .register__forgot:hover {
    text-decoration: underline; }

.register_success .register__form-wrapper,
.register_success .register__button {
  display: none; }

.register_success .register__success-message {
  display: block; }

@media (min-width: 320px) and (max-width: 480px) {
  .login {
    background-color: #29A3BB; }
    .login__container {
      width: 100%;
      bottom: 0;
      position: absolute; }
    .login__header {
      padding: 0 0 50px;
      border: 0;
      text-align: center; }
    .login__logo {
      height: 45px;
      width: 165px; }
    .login__action {
      text-align: center;
      padding-bottom: 20px; }
    .login__button {
      float: none;
      display: block;
      width: 100%;
      margin-bottom: 20px; }
    .login__forgot {
      float: none; } }

.forgot-password__container {
  width: 410px;
  margin: 0 auto;
  background-color: #29A3BB; }

.forgot-password__header {
  padding: 15px 20px 7px;
  border-bottom: 1px solid #fff;
  overflow: hidden; }

.forgot-password__logo {
  float: left; }

.forgot-password__title {
  float: right;
  margin: 0;
  padding: 4px 0 0;
  color: #fff;
  font-size: 16px;
  font-weight: bold; }

.forgot-password__content {
  padding: 20px; }

.forgot-password__error-message {
  padding: 11px;
  margin-bottom: 20px;
  color: #fff;
  background: #d84d4d; }

.forgot-password__success-message {
  margin-bottom: 20px;
  padding: 11px;
  background: #cae431; }

.forgot-password__intro {
  margin-top: 0;
  color: #fff;
  font-size: 14px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }

.forgot-password__form-wrapper {
  padding-bottom: 15px; }

.forgot-password__text {
  border: 0;
  margin: 0;
  padding: 15px;
  width: 100%;
  font-size: 16px; }
  .forgot-password__text_error {
    border: 1px solid red;
    background-color: #fee; }

.forgot-password__action {
  overflow: hidden;
  padding-top: 20px; }

.forgot-password__button {
  float: left;
  padding: 15px 60px;
  text-decoration: none;
  color: #005567;
  font-size: 14px;
  font-weight: bold;
  background: #CAE431;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5); }
  .forgot-password__button:hover {
    background: #c2de1d; }

.forgot-password__login {
  float: right;
  margin-top: 13px;
  text-decoration: none;
  color: #fff;
  font-size: 14px; }
  .forgot-password__login:hover {
    text-decoration: underline; }

@media (min-width: 320px) and (max-width: 480px) {
  .login {
    background-color: #29A3BB; }
    .login__container {
      width: 100%;
      bottom: 0;
      position: absolute; }
    .login__header {
      padding: 0 0 50px;
      border: 0;
      text-align: center; }
    .login__logo {
      height: 45px;
      width: 165px; }
    .login__action {
      text-align: center;
      padding-bottom: 20px; }
    .login__button {
      float: none;
      display: block;
      width: 100%;
      margin-bottom: 20px; }
    .login__forgot {
      float: none; } }

.landing {
  text-align: center; }

