.header {
  height: 60px;
  background: #29A3BB;

  &__logo-wrapper {
    float: left;
    height: 60px;
    padding: 15px 30px 0 0;
  }

  &__logo {
    float: left;
  }

  &__nav {
    float: left;
    height: 60px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    padding: 20px 15px 0;

    &:hover,
    &_selected {
      background: #168BA2;
    }

  }
  &__right {
    float: right;
  }
}
